import { Progress } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useQueryCarrierLatestMeasurements from '~/apollo/hooks/measurements/useQueryCarrierLatestMeasurements';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import getRiskScoreLetter from '~/utils/frontendMetrics/getRiskScoreLetter';

import MetricCard, { CardDiv, H3 } from './components/MetricCard';

const WrapperDiv = styled.div`
  margin-bottom: 1.5rem;
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  margin-bottom: 1rem;

  ${theme.medias.extraSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const RiskScoreLetterDiv = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${theme.colors.white};
  padding: 0.5rem 1rem;
  border-radius: 8px;
`;

interface Props {
  agent: Agent | undefined;
}

const SectionFrontendMetrics = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();
  const { stressLevel, heatStressLevel, gasExposureLevel, isLoading } =
    useQueryCarrierLatestMeasurements({ carrierId: agent?.id });

  const fatigueLevel = parseInt(agent?.attributes?.fatigue_score_frontend_metric || '0', 10);

  const items = useMemo(
    () => [
      ...(companyFeatures.heartRateSensor || companyFeatures.oxygenSupplySensor
        ? [
            {
              title: i18n.t('frontendMetrics.stress'),
              percent: stressLevel,
            },
          ]
        : []),
      ...(fatigueLevel
        ? [
            {
              title: i18n.t('frontendMetrics.fatigue'),
              percent: fatigueLevel * 10,
            },
          ]
        : []),
      ...(companyFeatures.physiologicalTemperatureSensor
        ? [
            {
              title: i18n.t('frontendMetrics.heatStress'),
              percent: heatStressLevel,
            },
          ]
        : []),
      ...(companyFeatures.gasSensor || companyFeatures.radiationSensor
        ? [
            {
              title: i18n.t('frontendMetrics.gasExposure'),
              percent: gasExposureLevel,
            },
          ]
        : []),
    ],
    [
      stressLevel,
      fatigueLevel,
      heatStressLevel,
      gasExposureLevel,
      companyFeatures.heartRateSensor,
      companyFeatures.oxygenSupplySensor,
      companyFeatures.physiologicalTemperatureSensor,
      companyFeatures.gasSensor,
      companyFeatures.radiationSensor,
    ],
  );

  const riskScore = parseInt(agent?.attributes?.daily_health_score_frontend_metric || '0', 10);

  const { scoreLetter, scoreColor } = useMemo(() => getRiskScoreLetter(riskScore), [riskScore]);

  return (
    <WrapperDiv>
      <GridDiv>
        {items.map((item) => (
          <MetricCard
            key={item.title}
            title={item.title}
            percent={item.percent}
            isLoading={isLoading}
          />
        ))}
      </GridDiv>
      {riskScore > 0 && (
        <CardDiv>
          <FlexDiv>
            <RiskScoreLetterDiv style={{ backgroundColor: scoreColor }}>
              {scoreLetter}
            </RiskScoreLetterDiv>
            <div>
              <H3>{i18n.t('frontendMetrics.riskScore')}</H3>
              <Progress
                percent={riskScore * 10}
                steps={10}
                strokeColor={scoreColor}
                style={{
                  width: '100%',
                }}
                showInfo={false}
              />
            </div>
          </FlexDiv>
        </CardDiv>
      )}
    </WrapperDiv>
  );
});

SectionFrontendMetrics.displayName = 'SectionFrontendMetrics';

export default SectionFrontendMetrics;
