import CompassOutlined from '@ant-design/icons/CompassOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import { Button, Popover, Table, TableColumnsType, Tooltip } from 'antd';
import camelCase from 'lodash/camelCase';
import { Key, memo, ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useInitialLoading from '~/hooks/useInitialLoading';
import useTableSearch from '~/hooks/useTableSearch';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { Agent, AGENT_STATUS, AgentAlertLevel } from '~/types/agent';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import getAgentStatusTranslation from '~/utils/agent/getAgentStatusTranslation';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';
import { computeMissionTime } from '~/utils/dateTime';
import getEquipmentStatusLabel from '~/utils/equipment/getEquipmentStatusLabel';
import getRiskScoreLetter from '~/utils/frontendMetrics/getRiskScoreLetter';
import curateUrl from '~/utils/parse/curateUrl';

import computeEquipmentStatuses from './utils/computeEquipmentStatuses';

interface TableDataType {
  key: Key;
  id: string;
  agent: Agent;
  completeName: string;
  status: AGENT_STATUS;
  alertLevel: AgentAlertLevel;
  isOffline: boolean;
  isConnectionLost: boolean;
  acronym: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  team: string;
  vehicle: string;
  deviceName: string;
  equipmentStatus: string;
  equipmentStatusColor: string;
  missionStart: string;
  missionEnd: string;
  riskScore: number;
  actions: ReactNode;
}

const StyledTable = styled(Table<TableDataType>)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const PopoverSummaryDl = styled.dl`
  margin: 0;

  & > div {
    display: flex;
    gap: 4px;

    dt {
      margin: 0;
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }
`;

const CIRCLE_WIDTH = '38px';

const ProfileCircleDiv = styled.div`
  align-items: center;
  display: flex;
  font-weight: regular;
  justify-content: center;
  border-radius: 32px;
  font-size: 12px;
  height: ${CIRCLE_WIDTH};
  width: ${CIRCLE_WIDTH};
  margin: 0;
`;

const RiskScoreLetterDiv = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${theme.colors.white};
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: inline;
`;

const AgentsTable = memo(() => {
  const { isInitialLoading } = useInitialLoading();
  const { agents } = useAgentsContext();
  const { openModal } = useModalsContext();
  const { companyFeatures, showLocationHistoryFeature } = useCompanyFeatures();
  const { isSuperAdmin } = useCurrentUserContext();
  const navigate = useNavigate();

  const { getColumnSearchProps } = useTableSearch<TableDataType>();

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'completeName',
        title: i18n.t('common.agent'),
        sorter: (a, b) => a.completeName.toLowerCase().localeCompare(b.completeName.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'completeName',
          title: i18n.t('common.agent'),
          renderWithHighlight: ({ text, record, highlightedNode }) => {
            const agentStatusColor = getAgentStatusColor(record);
            const profileCircleDiv = (
              <ProfileCircleDiv
                style={{
                  backgroundColor: agentStatusColor,
                  borderColor: agentStatusColor,
                  color: theme.colors.white,
                }}
                data-id="details-popup-bullet-label"
              >
                {record?.acronym}
              </ProfileCircleDiv>
            );

            return text ? (
              <div
                data-id={`agent-name-${record.agent.email || record.agent.id}`}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                {!record.isOffline || !!record.alertLevel ? (
                  <Link to={routes.status({ id: record.agent.id })}>
                    <Tooltip title={i18n.t('agentsPage.viewDetails')}>{profileCircleDiv}</Tooltip>
                  </Link>
                ) : (
                  profileCircleDiv
                )}
                <Popover
                  placement="top"
                  content={
                    <PopoverSummaryDl data-id="agent-attributes">
                      {(['first_name', 'last_name', 'phone_number', 'team'] as const).map(
                        (attributeName) => (
                          <div key={attributeName}>
                            <dt>{i18n.t(`agentForm.${camelCase(attributeName)}`)}:</dt>
                            <dd style={{ opacity: record[attributeName] ? 1 : 0.35 }}>
                              {record[attributeName] || i18n.t('agentForm.unknown')}
                            </dd>
                          </div>
                        ),
                      )}
                    </PopoverSummaryDl>
                  }
                >
                  <span style={{ cursor: 'help' }}>
                    <b>{highlightedNode}</b>
                  </span>
                </Popover>
              </div>
            ) : (
              '-'
            );
          },
        }),
      },
      {
        dataIndex: 'team',
        title: i18n.t('common.team'),
        sorter: (a, b) => a.team.toLowerCase().localeCompare(b.team.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'team',
          title: i18n.t('common.team'),
          renderWithHighlight: ({ text, highlightedNode }) => (text ? highlightedNode : '-'),
        }),
      },
      {
        dataIndex: 'status',
        title: i18n.t('common.agentStatus'),
        defaultSortOrder: 'ascend',
        sorter: (a: TableDataType, b: TableDataType) => {
          const sorted = sortAgentsByStatusAndName([a.agent, b.agent]);
          return sorted.indexOf(a.agent) - sorted.indexOf(b.agent);
        },
        render: (text, record) => {
          const agentStatusColor = getAgentStatusColor(record);
          return text ? (
            <div
              style={{
                display: 'inline-block',
                borderRadius: '3px',
                color:
                  record.isOffline && record.status !== AGENT_STATUS.alert
                    ? theme.colors.darkGrey
                    : theme.colors.white,
                fontSize: '15px',
                backgroundColor: agentStatusColor,
                padding: '5px 10px',
              }}
            >
              {getAgentStatusTranslation({
                isOffline: record.isOffline,
                status: record.status,
              })}
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        dataIndex: 'deviceName',
        title: i18n.t('common.wearinBrain'),
        sorter: (a, b) => a.deviceName.toLowerCase().localeCompare(b.deviceName.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'deviceName',
          title: i18n.t('common.wearinBrain'),
          renderWithHighlight: ({ text, highlightedNode }) =>
            text ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <img
                  src={curateUrl('/icons/brain.svg')}
                  alt="Brain"
                  style={{
                    color: theme.colors.darkBlue,
                    height: 24,
                  }}
                />
                {highlightedNode}
              </div>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'equipmentStatus',
        title: i18n.t('agentsPage.equipmentStatus'),
        sorter: (a, b) =>
          a.equipmentStatus.toLowerCase().localeCompare(b.equipmentStatus.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'equipmentStatus',
          title: i18n.t('agentsPage.equipmentStatus'),
          renderWithHighlight: ({ text, record, highlightedNode }) => {
            const equipmentStatuses = computeEquipmentStatuses({
              agent: record.agent,
              isOffline: record.isOffline,
              isConnectionLost: record.isConnectionLost,
              companyFeatures,
            });
            const equipmentStatusPopoverItems: {
              title: string;
              color: string;
              label: string;
              description: string;
            }[] = [
              {
                title: i18n.t('common.wearinBrain'),
                color: equipmentStatuses.brain.color.popover,
                label: equipmentStatuses.brain.label,
                description: '',
              },
              ...(companyFeatures.emergencyButton
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.sosExternalButton'),
                      color: equipmentStatuses.emergencyButton.color.popover,
                      label: equipmentStatuses.emergencyButton.label,
                      description: getEquipmentStatusLabel(
                        record.agent.equipmentStatus.emergencyButton,
                      ),
                    },
                  ]
                : []),
              ...(companyFeatures.heartRateSensor
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.heartRate'),
                      color: equipmentStatuses.heartRate.color.popover,
                      label: equipmentStatuses.heartRate.label,
                      description: getEquipmentStatusLabel(record.agent.equipmentStatus.heartRate),
                    },
                  ]
                : []),
              ...(companyFeatures.physiologicalTemperatureSensor
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.physiologicalTemperature'),
                      color: equipmentStatuses.physiologicalTemperature.color.popover,
                      label: equipmentStatuses.physiologicalTemperature.label,
                      description: getEquipmentStatusLabel(
                        record.agent.equipmentStatus.physiologicalTemperature,
                      ),
                    },
                  ]
                : []),
              ...(companyFeatures.gasSensor
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.gas'),
                      color: equipmentStatuses.gas.color.popover,
                      label: equipmentStatuses.gas.label,
                      description: getEquipmentStatusLabel(record.agent.equipmentStatus.gas),
                    },
                  ]
                : []),
              ...(companyFeatures.radiationSensor
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.radiation'),
                      color: equipmentStatuses.radiation.color.popover,
                      label: equipmentStatuses.radiation.label,
                      description: getEquipmentStatusLabel(record.agent.equipmentStatus.radiation),
                    },
                  ]
                : []),
              ...(companyFeatures.oxygenSupplySensor
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.oxygenSupply'),
                      color: equipmentStatuses.oxygenSupply.color.popover,
                      label: equipmentStatuses.oxygenSupply.label,
                      description: getEquipmentStatusLabel(
                        record.agent.equipmentStatus.oxygenSupply,
                      ),
                    },
                  ]
                : []),
              ...(companyFeatures.impactDetectionFront
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.traakFront'),
                      color: equipmentStatuses.traakFront.color.popover,
                      label: equipmentStatuses.traakFront.label,
                      description: getEquipmentStatusLabel(record.agent.equipmentStatus.traakFront),
                    },
                  ]
                : []),
              ...(companyFeatures.impactDetectionBack
                ? [
                    {
                      title: i18n.t('carrierDetailsPopup.equipment.traakBack'),
                      color: equipmentStatuses.traakBack.color.popover,
                      label: equipmentStatuses.traakBack.label,
                      description: getEquipmentStatusLabel(record.agent.equipmentStatus.traakBack),
                    },
                  ]
                : []),
            ];
            const showPopover = !record?.agent?.isOffline;
            return text ? (
              <Popover
                placement="top"
                content={
                  showPopover ? (
                    <PopoverSummaryDl data-id="agent-equipment-details">
                      {equipmentStatusPopoverItems.map((item) => (
                        <div key={item.title}>
                          <dt>{item.title}:</dt>
                          <dd data-id={item.title} style={{ color: item.color }}>
                            {item.description ? item.description : item.label}
                          </dd>
                        </div>
                      ))}
                    </PopoverSummaryDl>
                  ) : null
                }
              >
                <div
                  data-id={`agent-equipment-status-${record.agent.email || record.agent.id}`}
                  style={{
                    display: 'inline-block',
                    cursor: showPopover ? 'help' : 'default',
                    color: equipmentStatuses.status.color.table,
                  }}
                >
                  {highlightedNode}
                </div>
              </Popover>
            ) : (
              '-'
            );
          },
        }),
      },
      ...(companyFeatures.frontendMetrics
        ? [
            {
              dataIndex: 'riskScore',
              title: i18n.t('frontendMetrics.riskScore'),
              sorter: (a: TableDataType, b: TableDataType) => a.riskScore - b.riskScore,
              render: (text: number, record: TableDataType) => {
                const { scoreLetter, scoreColor } = getRiskScoreLetter(record.riskScore);

                return record?.riskScore && (!record.isOffline || !!record.alertLevel) ? (
                  <RiskScoreLetterDiv style={{ backgroundColor: scoreColor }}>
                    {scoreLetter}
                  </RiskScoreLetterDiv>
                ) : (
                  '-'
                );
              },
            },
          ]
        : []),
      {
        dataIndex: 'missionStart',
        title: i18n.t('agentsPage.missionStart'),
        sorter: (a, b) => a.missionStart.toLowerCase().localeCompare(b.missionStart.toLowerCase()),
        render: (text, record) => record?.missionStart || '-',
      },
      {
        dataIndex: 'missionEnd',
        title: i18n.t('agentsPage.missionEnd'),
        sorter: (a, b) => a.missionEnd.toLowerCase().localeCompare(b.missionEnd.toLowerCase()),
        render: (text, record) => record?.missionEnd || '-',
      },
      {
        dataIndex: 'vehicle',
        title: i18n.t('common.vehicle'),
        sorter: (a: TableDataType, b: TableDataType) =>
          a.vehicle.toLowerCase().localeCompare(b.vehicle.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'vehicle',
          title: i18n.t('common.vehicle'),
          renderWithHighlight: ({ text, highlightedNode }) => (text ? highlightedNode : '-'),
        }),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
        render: (text, record) => {
          const isAvailable =
            !record?.agent?.isOffline || record?.agent?.status === AGENT_STATUS.alert;

          return (
            <ActionDiv>
              <Tooltip
                title={
                  isAvailable
                    ? i18n.t('agentsPage.viewDetails')
                    : i18n.t('agentsPage.cannotViewOfflineAgentDetails')
                }
              >
                <Button
                  disabled={!isAvailable}
                  onClick={() => {
                    navigate(
                      routes.status({
                        id: record?.agent?.id,
                      }),
                    );
                  }}
                  data-id="agent-details-btn"
                  data-agent-id={record?.agent?.id}
                  data-agent-email={record?.agent?.email}
                  icon={<EyeOutlined />}
                />
              </Tooltip>
              {showLocationHistoryFeature && (
                <Tooltip title={i18n.t('agentsPage.locationHistory')}>
                  <Button
                    onClick={() => {
                      if (record?.agent?.id) {
                        openModal({
                          type: 'locationHistory',
                          agentId: record?.agent?.id,
                        });
                      }
                    }}
                    data-id="location-history-btn"
                    icon={<CompassOutlined />}
                  />
                </Tooltip>
              )}
              {companyFeatures.playbackRecordedVideos && (
                <Tooltip title={i18n.t('agentsPage.videoRecordings')}>
                  <Button
                    onClick={() => {
                      if (record?.agent?.id) {
                        openModal({
                          type: 'videoRecordings',
                          defaultAgentId: record?.agent?.id,
                          initialDateRange: ['', ''],
                        });
                      }
                    }}
                    data-id="video-recordings-btn"
                    icon={<VideoCameraOutlined />}
                  />
                </Tooltip>
              )}
              {companyFeatures.agentDebugPanel && isSuperAdmin && (
                <Tooltip title={i18n.t('agentDebugModal.buttonTooltip')} placement="top">
                  <Button
                    data-id={`agent-debug-panel-btn-${record.agent.email || record.agent.id}`}
                    type="dashed"
                    icon={<LockOutlined />}
                    onClick={() => {
                      if (record?.id) {
                        openModal({
                          type: 'agentDebug',
                          agentId: record.id,
                        });
                      }
                    }}
                  />
                </Tooltip>
              )}
            </ActionDiv>
          );
        },
      },
    ],
    [
      companyFeatures,
      getColumnSearchProps,
      isSuperAdmin,
      navigate,
      openModal,
      showLocationHistoryFeature,
    ],
  );

  const data: TableDataType[] = useMemo(
    () =>
      agents.map((agent) => {
        const equipmentStatuses = computeEquipmentStatuses({
          agent,
          isOffline: agent.isOffline,
          isConnectionLost: agent.isConnectionLost,
          companyFeatures,
        });
        return {
          key: agent.email || agent.id,
          id: agent.id,
          agent,
          completeName: agent.completeName,
          status: agent.status,
          alertLevel: agent.alertLevel,
          isOffline: agent.isOffline,
          isConnectionLost: agent.isConnectionLost,
          acronym: agent.attributes.acronym || '',
          first_name: agent.attributes.first_name || '',
          last_name: agent.attributes.last_name || '',
          phone_number: agent.attributes.phone_number || '',
          team: agent.attributes.team || '',
          vehicle: agent.attributes.plate_number || '',
          deviceName: agent.deviceName || '',
          equipmentStatus: equipmentStatuses.status.label || '',
          equipmentStatusColor: equipmentStatuses.status.color.table,
          missionStart: computeMissionTime(agent.missionStartTimeISO),
          missionEnd: computeMissionTime(agent.missionEndTimeISO),
          riskScore: parseInt(agent?.attributes?.daily_health_score_frontend_metric || '0', 10),
          actions: '',
        };
      }),
    [agents, companyFeatures],
  );

  return (
    <StyledTable
      data-id="agents-table"
      tableLayout="auto"
      loading={isInitialLoading}
      columns={columns}
      dataSource={data}
      sortDirections={['ascend', 'descend', 'ascend']}
      pagination={{
        defaultPageSize: 5000,
        showSizeChanger: false,
        pageSizeOptions: undefined,
        hideOnSinglePage: true,
        position: [],
      }}
    />
  );
});

AgentsTable.displayName = 'AgentsTable';

export default AgentsTable;
