export const GREEN_COLOR = '#87d068';
export const YELLOW_COLOR = '#ffc107';
export const RED_COLOR = '#ff4d4f';

export default function getRiskScoreLetter(riskScore: number): {
  scoreLetter: string;
  scoreColor: string;
} {
  if (riskScore >= 8.5) {
    return { scoreLetter: 'A+', scoreColor: GREEN_COLOR };
  }
  if (riskScore >= 7.5) {
    return { scoreLetter: 'A', scoreColor: GREEN_COLOR };
  }
  if (riskScore >= 5.5) {
    return { scoreLetter: 'B', scoreColor: YELLOW_COLOR };
  }
  if (riskScore >= 3.5) {
    return { scoreLetter: 'C', scoreColor: YELLOW_COLOR };
  }
  if (riskScore >= 1.5) {
    return { scoreLetter: 'D', scoreColor: RED_COLOR };
  }
  return { scoreLetter: 'E', scoreColor: RED_COLOR };
}
