import { Progress } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from '~/utils/frontendMetrics/getRiskScoreLetter';

export const CardDiv = styled.div`
  background: ${theme.colors.greyBackground};
  padding: 1rem;
  border-radius: 8px;
`;

export const H3 = styled.h3`
  margin: 0;
`;

const FlexDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  align-items: center;
  gap: 0.75rem;
`;

interface Props {
  title: string;
  percent: number | null;
  isLoading: boolean;
}

const MetricCard = memo(({ title, percent, isLoading }: Props) => {
  const curatedPercent = useMemo(() => {
    if (!percent || percent < 10) {
      return 10;
    }
    if (percent > 90) {
      return 90;
    }
    return percent;
  }, [percent]);

  const percentLabel = useMemo(() => {
    if (curatedPercent < 33) {
      return i18n.t('frontendMetrics.good');
    }
    if (curatedPercent < 66) {
      return i18n.t('frontendMetrics.mid');
    }
    return i18n.t('frontendMetrics.high');
  }, [curatedPercent]);

  return (
    <CardDiv>
      <H3>{title}</H3>
      <FlexDiv>
        <Progress
          percent={isLoading ? 0 : curatedPercent}
          steps={10}
          strokeColor={[
            GREEN_COLOR,
            GREEN_COLOR,
            GREEN_COLOR,
            YELLOW_COLOR,
            YELLOW_COLOR,
            YELLOW_COLOR,
            YELLOW_COLOR,
            RED_COLOR,
            RED_COLOR,
            RED_COLOR,
          ]}
          style={{
            width: '100%',
          }}
          showInfo={false}
        />
        <div>{isLoading ? i18n.t('common.loading') : percentLabel}</div>
      </FlexDiv>
    </CardDiv>
  );
});

MetricCard.displayName = 'MetricCard';

export default MetricCard;
