import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import {
  SENSOR_NAME_VARIABLE,
  SENSOR_NAME_QUERY,
  SENSOR_STATUS_VARIABLE,
  SENSOR_STATUS_QUERY,
  type GasSensorType,
  type HeartRateSensorType,
  type PhysiologicalTemperatureSensorType,
} from '~/types/sensor';

interface InputType {
  carrierId: string;
}

interface MeasurementConnection<V> {
  __typename?: 'MeasurementConnection';
  items: {
    __typename?: 'Measurement';
    timestamp: string;
    value: V;
  }[];
}

interface OutputType {
  carrier: {
    device: {
      [key in SENSOR_NAME_VARIABLE]?: MeasurementConnection<string>;
    };
  };
}

function getSomeMinutesAgo() {
  return new Date(Date.now() - 24 * 60 * 1000);
}

function filterMeasurements(
  items: {
    __typename?: 'Measurement';
    timestamp: string;
    value: string;
  }[],
) {
  return items
    .filter((item) => new Date(item.timestamp) > getSomeMinutesAgo())
    .map((item) => JSON.parse(item.value));
}

export default function useQueryCarrierLatestMeasurements({
  carrierId,
}: {
  carrierId: string | undefined;
}) {
  const { data, loading, error } = useQuery<OutputType, InputType>(
    gql`
      query QueryAgentListWithDetails($carrierId: ID!) {
        carrier(id: $carrierId) {
          device {
            ${SENSOR_NAME_VARIABLE.heartRate}: measurements(type: "${SENSOR_NAME_QUERY.heart_rate}", limit: 1) ${MEASUREMENT_ITEMS_QUERY_BODY}
            ${SENSOR_STATUS_VARIABLE.heartRateStatus}: measurements(type: "${SENSOR_STATUS_QUERY.heart_rate_status}", limit: 1) ${MEASUREMENT_ITEMS_QUERY_BODY}
            ${SENSOR_NAME_VARIABLE.bodyMultiSensorV1}: measurements(type: "${SENSOR_NAME_QUERY.body_multi_sensor_v1}", limit: 1) ${MEASUREMENT_ITEMS_QUERY_BODY}
            ${SENSOR_STATUS_VARIABLE.bodyMultiSensorV1Status}: measurements(type: "${SENSOR_STATUS_QUERY.body_multi_sensor_v1_status}", limit: 1) ${MEASUREMENT_ITEMS_QUERY_BODY}
            ${SENSOR_NAME_VARIABLE.gas}: measurements(type: "${SENSOR_NAME_QUERY.gas}", limit: 1) ${MEASUREMENT_ITEMS_QUERY_BODY}
            ${SENSOR_STATUS_VARIABLE.gasStatus}: measurements(type: "${SENSOR_STATUS_QUERY.gas_status}", limit: 1) ${MEASUREMENT_ITEMS_QUERY_BODY}
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip: !carrierId,
      variables: {
        carrierId: carrierId || '',
      },
      pollInterval: 1000, // Poll every 2 seconds
    },
  );

  const isLoading = loading && !data;

  const heartRateMeasurements: HeartRateSensorType['value'][] = useMemo(
    () => filterMeasurements(data?.carrier?.device?.[SENSOR_NAME_VARIABLE.heartRate]?.items || []),
    [data?.carrier?.device],
  );

  const physiologicalTemperatureMeasurements: PhysiologicalTemperatureSensorType['value'][] =
    useMemo(
      () =>
        filterMeasurements(
          data?.carrier?.device?.[SENSOR_NAME_VARIABLE.bodyMultiSensorV1]?.items || [],
        ),
      [data?.carrier?.device],
    );

  const gasMeasurements: GasSensorType['value'][] = useMemo(
    () => filterMeasurements(data?.carrier?.device?.[SENSOR_NAME_VARIABLE.gas]?.items || []),
    [data?.carrier?.device],
  );

  const heartRateAverage = useMemo(
    () => heartRateMeasurements.reduce((acc, curr) => acc + curr, 0) / heartRateMeasurements.length,
    [heartRateMeasurements],
  );

  const bodyTemperatureAverage = useMemo(
    () =>
      physiologicalTemperatureMeasurements.reduce((acc, curr) => acc + curr.body_temp, 0) /
      physiologicalTemperatureMeasurements.length,
    [physiologicalTemperatureMeasurements],
  );

  const skinTemperatureAverage = useMemo(
    () =>
      physiologicalTemperatureMeasurements.reduce((acc, curr) => acc + curr.skin_temp, 0) /
      physiologicalTemperatureMeasurements.length,
    [physiologicalTemperatureMeasurements],
  );

  const co2Average = useMemo(
    () => gasMeasurements.reduce((acc, curr) => acc + curr.co2, 0) / gasMeasurements.length,
    [gasMeasurements],
  );

  // Calculate stress level (0-100) based on heart rate average
  const stressLevel = useMemo(() => {
    const MIN_HEART_RATE = 60; // bpm
    const MAX_HEART_RATE = 180; // bpm

    // 0 is At MIN_HEART_RATE, 100 is at MAX_HEART_RATE
    return Math.min(
      100,
      ((heartRateAverage - MIN_HEART_RATE) / (MAX_HEART_RATE - MIN_HEART_RATE)) * 100,
    );
  }, [heartRateAverage]);

  // Calculate heat stress level (0-100) using body/skin temperature
  const heatStressLevel = useMemo(() => {
    if (!bodyTemperatureAverage && !skinTemperatureAverage) {
      return 0;
    }

    const MIN_BODY_TEMP = 36;
    const MAX_BODY_TEMP = 45;
    const MIN_SKIN_TEMP = 30;
    const MAX_SKIN_TEMP = 40;

    let score = 0;

    score += Math.min(
      100,
      ((bodyTemperatureAverage - MIN_BODY_TEMP) / (MAX_BODY_TEMP - MIN_BODY_TEMP)) * 100,
    );
    score += Math.min(
      100,
      ((skinTemperatureAverage - MIN_SKIN_TEMP) / (MAX_SKIN_TEMP - MIN_SKIN_TEMP)) * 100,
    );

    return Math.min(100, score / 2);
  }, [bodyTemperatureAverage, skinTemperatureAverage]);

  // Calculate gas exposure level (0-100) using gas and radiation data
  const gasExposureLevel = useMemo(() => {
    // CO2 (carbon dioxide) - dangerous above 5000 ppm
    const score = Math.min(100, (co2Average / 5000) * 100);

    return Math.min(100, score);
  }, [co2Average]);

  return useMemo(
    () => ({
      stressLevel,
      heatStressLevel,
      gasExposureLevel,
      isLoading,
      error,
    }),
    [stressLevel, heatStressLevel, gasExposureLevel, isLoading, error],
  );
}
