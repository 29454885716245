import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { Alert, Modal } from 'antd';
import isBoolean from 'lodash/isBoolean';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import GenericItemList, { DEFAULT_VALUE, type GenericItem } from '~/components/GenericItemList';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { AgentAttributes } from '~/types/agent';
import { EQUIPMENT_STATUS } from '~/types/equipment';
import type { ModalProps } from '~/types/modal';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';

type RequiredAgentAttributes = Required<AgentAttributes>;

const knownAgentAttributes = Object.keys({
  acronym: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  team: '',
  plate_number: '',
  in_safe_zone: false,
  daily_health_score_frontend_metric: '',
  fatigue_score_frontend_metric: '',
} satisfies RequiredAgentAttributes);

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  margin-bottom: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const SectionTitleH2 = styled.h2`
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.colors.darkBlue};
`;

const BottomDiv = styled.div`
  margin-top: 16px;
  font-size: 12px;
  opacity: 0.5;
`;

function getLabel(key: string): string {
  return startCase(key);
}

export interface AgentDebugModalProps {
  agentId: string | undefined;
}

const AgentDebugModal = memo(({ isOpen, onClose, agentId }: ModalProps & AgentDebugModalProps) => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { getAgent } = useAgentsContext();

  const agent = useMemo(() => (agentId ? getAgent(agentId) : undefined), [agentId, getAgent]);

  const { id, attributes, sensors, equipmentStatus, ...remainingProperties } = agent || {};

  const agentItems: GenericItem[] = useMemo(
    () =>
      Object.entries(remainingProperties || {}).map(([key, value]) => ({
        label: getLabel(key),
        value:
          typeof value === 'string' || isBoolean(value)
            ? value?.toString() || DEFAULT_VALUE
            : JSON.stringify(value),
      })),
    [remainingProperties],
  );

  const knownAttributeItems: GenericItem[] = useMemo(
    () =>
      orderBy(
        knownAgentAttributes.map((key) => {
          const valueAttributes = (attributes || {}) as AgentAttributes;
          const value = valueAttributes?.[key as keyof AgentAttributes];

          return {
            label: getLabel(key),
            value: value || DEFAULT_VALUE,
          };
        }),
        ['label'],
        ['asc'],
      ),
    [attributes],
  );

  const sensorItems: GenericItem[] = useMemo(
    () =>
      orderBy(
        Object.entries(sensors || {}).map(([key, value]) => ({
          label: getLabel(key),
          value: value ? JSON.stringify(value) : DEFAULT_VALUE,
        })),
        ['label'],
        ['asc'],
      ),
    [sensors],
  );

  const equipmentItems: GenericItem[] = useMemo(
    () =>
      orderBy(
        Object.entries(equipmentStatus || {}).map(([key, { status }]) => {
          const statusColor = getEquipmentStatusDysfunctionsColor(status);

          return {
            label: getLabel(key),
            value: (
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    background: statusColor.color,
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                />
                <b>
                  {status === EQUIPMENT_STATUS.no_error
                    ? 'OK'
                    : i18n.t<string>(`general.equipment.status.${status}`)}
                </b>
              </div>
            ),
          };
        }),
        ['label'],
        ['asc'],
      ),
    [equipmentStatus],
  );

  if (!isSuperAdmin || !agent) {
    return null;
  }

  return (
    <Modal
      title={agent?.completeName}
      footer={null}
      centered
      width={980}
      open={isOpen}
      onCancel={onClose}
    >
      <Alert
        style={{ marginBottom: '12px' }}
        message={
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: i18n.t<string>('agentDebugModal.helpTextHtml', {
                agentName: agent?.completeName,
              }),
            }}
          />
        }
        type="info"
        showIcon
      />
      <GridDiv>
        <div>
          <SectionTitleH2>
            {i18n.t('common.details')} ({agentItems.length})
          </SectionTitleH2>
          <GenericItemList items={agentItems} />
        </div>
        <div>
          <SectionTitleH2>
            {i18n.t('agentDebugModal.equipment')} ({equipmentItems.length})
          </SectionTitleH2>
          <GenericItemList items={equipmentItems} />
        </div>
        <div>
          <SectionTitleH2>
            {i18n.t('agentDebugModal.attributes')} ({knownAttributeItems.length})
          </SectionTitleH2>
          <GenericItemList items={knownAttributeItems} />
        </div>
        <div>
          <SectionTitleH2>
            {i18n.t('agentDebugModal.sensors')} ({sensorItems.length})
          </SectionTitleH2>
          <GenericItemList items={sensorItems} />
        </div>
      </GridDiv>
      <BottomDiv>
        <InfoCircleOutlined /> <b>{i18n.t('agentDebugModal.agentId')}:</b> {id}
      </BottomDiv>
    </Modal>
  );
});

export default AgentDebugModal;
